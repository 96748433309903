"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@aws-amplify/core");
exports.Amplify = core_1.Amplify;
var analytics_1 = require("@aws-amplify/analytics");
exports.Analytics = analytics_1.Analytics;
exports.AWSPinpointProvider = analytics_1.AWSPinpointProvider;
exports.AWSKinesisProvider = analytics_1.AWSKinesisProvider;
exports.AWSKinesisFirehoseProvider = analytics_1.AWSKinesisFirehoseProvider;
exports.AmazonPersonalizeProvider = analytics_1.AmazonPersonalizeProvider;
var auth_1 = require("@aws-amplify/auth");
exports.Auth = auth_1.Auth;
var storage_1 = require("@aws-amplify/storage");
exports.Storage = storage_1.Storage;
exports.StorageClass = storage_1.StorageClass;
var api_1 = require("@aws-amplify/api");
exports.API = api_1.API;
exports.APIClass = api_1.APIClass;
exports.graphqlOperation = api_1.graphqlOperation;
var datastore_1 = require("@aws-amplify/datastore");
exports.AuthModeStrategyType = datastore_1.AuthModeStrategyType;
exports.DataStore = datastore_1.DataStore;
exports.Predicates = datastore_1.Predicates;
exports.SortDirection = datastore_1.SortDirection;
exports.syncExpression = datastore_1.syncExpression;
var pubsub_1 = require("@aws-amplify/pubsub");
exports.PubSub = pubsub_1.PubSub;
var cache_1 = require("@aws-amplify/cache");
exports.Cache = cache_1.Cache;
var interactions_1 = require("@aws-amplify/interactions");
exports.Interactions = interactions_1.Interactions;
var notifications_1 = require("@aws-amplify/notifications");
exports.Notifications = notifications_1.Notifications;
var predictions_1 = require("@aws-amplify/predictions");
exports.Predictions = predictions_1.Predictions;
var core_2 = require("@aws-amplify/core");
exports.Logger = core_2.ConsoleLogger;
exports.Hub = core_2.Hub;
exports.ClientDevice = core_2.ClientDevice;
exports.Signer = core_2.Signer;
exports.I18n = core_2.I18n;
exports.ServiceWorker = core_2.ServiceWorker;
exports.AWSCloudWatchProvider = core_2.AWSCloudWatchProvider;
var withSSRContext_1 = require("./withSSRContext");
exports.withSSRContext = withSSRContext_1.withSSRContext;
var geo_1 = require("@aws-amplify/geo");
exports.Geo = geo_1.Geo;
